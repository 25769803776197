import * as React from 'react';
import WechatIMG114 from '../../images/cat-boarding-facility-1.jpeg';
import WechatIMG115 from '../../images/cat-boarding-facility-2.jpeg';
import WechatIMG116 from '../../images/cat-boarding-facility-3.jpeg';
import WechatIMG118 from '../../images/cat-boarding-facility-4.jpeg';
import Moment from '../../images/moment.jpg';

export default function StandardImageList() {
  return (
    <section className="product-images mt-10 px-4">
    <h1 className="text-xl font-medium text-orange-500 text-center mb-6">{'Moments'}</h1>
    <div className="grid grid-cols-2 md:grid-cols-3 gap-4">

        {itemData.map((item, index) => (
          <img
          src={item.img}
          key={index}
          srcSet={item.img}
          alt={item.title}
          className="w-full h-auto rounded-lg shadow-md"
        />))}

      </div>
    </section>

  );
}

const itemData = [
  {
    img: WechatIMG114,
    title: 'Cat Boarding Facility at T&J Cat Inn from www.catinn.sg',
  },
  {
    img: WechatIMG115,
    title: 'Cat Boarding Facility at T&J Cat Inn from www.catinn.sg',
  },
  {
    img: WechatIMG116,
    title: 'Cat Boarding Facility at T&J Cat Inn from www.catinn.sg',
  },
  {
    img: WechatIMG118,
    title: 'Cat Boarding Facility at T&J Cat Inn from www.catinn.sg',
  },
  {
    img: Moment,
    title: 'Cat Boarding Facility at T&J Cat Inn from www.catinn.sg',
  },
];