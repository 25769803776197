import { PhoneIcon, LocationMarkerIcon } from '@heroicons/react/solid'
import { MapIcon, ChatIcon } from '@heroicons/react/outline'
import parkingImg from '../images/parking.jpg'

export default function ContactUs() {
  return(
    <section id="contactus" className="px-2 py-10 md:py-20 md:px-0">
      <div className="container items-center max-w-6xl px-5 md:px-8 mx-auto xl:px-5">
        <div className="flex flex-wrap items-center sm:-mx-3">
          <div className="w-full md:w-1/2 md:px-3">
            <div className="w-full pb-6 space-y-6 sm:max-w-md lg:max-w-lg md:space-y-4 lg:space-y-8 xl:space-y-9 sm:pr-5 lg:pr-0 md:pb-0">
              <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-4xl lg:text-5xl xl:text-6xl">
                <span className="block text-orange-500 xl:inline">Locate Us</span>
              </h1>
              <div className="relative flex flex-col">

              <div className="flex flex-row items-center my-5 text-xl">
                  <PhoneIcon className="h-6 w-6 text-orange-500" />
                  <span className="pl-5"><a href="tel:84275351" target="_blank" className="text-gray-500">{'8427 5351 (Call Only)'}</a></span>
                </div>

                <div className="flex flex-row items-center my-5 text-xl">
                  <ChatIcon className="h-6 w-6 text-orange-500" />
                  <span className="pl-5"><a href="https://wa.me/+6587217587" target="_blank" className="text-gray-500">{'8721 7587 (WhatsApp Only)'}</a></span>
                </div>

                <div className="flex flex-row items-center my-5 text-xl">
                  <MapIcon className="h-6 w-6 text-orange-500" />
                  <div className="pl-5">
                    <div className="text-gray-500">{
                      '787 Geylang Rd, #02-01, Singapore 389674'
                    }</div>
                    <div className="text-gray-500">{
                      '(near Paya Lebar MRT)'
                    }</div>
                    
                    <a target="_blank" className="underline text-gray-500" href="https://www.google.com/maps/dir//T%26J+Cat+Inn+787+Geylang+Rd+%2302-01+Singapore+389674/@1.3147765,103.8916757,14z/data=!4m8!4m7!1m0!1m5!1m1!1s0x31da19aa9ca533ef:0xa0a3e64d28d39bc7!2m2!1d103.8916757!2d1.3147765?entry=ttu&g_ep=EgoyMDI1MDMxOS4yIKXMDSoJLDEwMjExNjM5SAFQAw%3D%3D">{'Get Directions by Drive'}</a>
                  </div>
                </div>

                <div className="text-gray-500">{
                  'Public parking is available along Lor 41 Geylang road.'
                }</div>

                <div className="text-gray-500">{
                  'Operating Hours: Monday to Sunday (9AM - 2PM, 3PM - 8PM)'
                }</div>

              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2">
            <div className="w-full h-auto overflow-hidden rounded-md shadow-xl sm:rounded-xl">
              <img
                src={parkingImg}
                alt="T&J Cat Inn Location"
                className="w-full h-auto object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
