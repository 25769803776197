import newImage from '../images/cat-boarding-facility.jpg';
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper";
import StandardImageList from './home/ImageList';
import { StarIcon } from '@heroicons/react/solid'

const reviews = [
  {
    name: 'Weion',
    review: `Second time leaving my cats with T&J! They are very nice and friendly, to both the owners and the cats! Super clean and hygenic while also making sure the cats are as comfortable as possible, especially on the first few days when it's normal for the cats to be a little nervous due to the change in environment. In the end my ragdoll didn't want to go home, and even after we did she still waited by our main door and meowed at the outside for a while😂`,
    rating: 5,
  },
  {
    name: 'Leelian Tan',
    review: `This wonderful hotel has been taking care of our cat, Sherlock three times—in December 2022, January 2024, and April 2024. Our cat is a very timid and shy. The T&J team worked very hard to play with and take care of Sherlock. He was very comfortable in all his three stays and always came home satisfied and happy. Thank you T&J team for taking care of Sherlock. We wholeheartedly recommend this hotel to any cat owner in Singapore 🐱`,
    rating: 5,
  },
  {
    name: 'Siti Julaiha',
    review: `We have been sending Puss & Boots here for boarding since 2022 and have been returning since. They have received excellent care during their stay from the friendly and professional team. They are also very patient with the kitties’ temperament as they get adjusted. We also appreciate the frequent updates (with plenty of photos and videos), especially when we miss them while away. There’s also good space for the kitties to explore and have a good stretch.`,
    rating: 5,
  },
];


export default function Home() {
  return(
    <>
    <Swiper
        spaceBetween={0}
        autoplay={{
          delay: 4000
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        className="w-full h-3/5 md:h-4/5"
      >

        <SwiperSlide className="flex bg-cover bg-center bg-cover2 md:bg-center">
          <div className="absolute bottom-8 left-10 right-10 rounded-lg bg-white/60 md:w-1/2 md:mx-auto">
          </div>
        </SwiperSlide>

        <SwiperSlide className="flex bg-cover bg-center bg-cover4">
          <div className="absolute bottom-8 left-10 right-10 rounded-lg bg-white/60 md:w-1/2 md:mx-auto">
            <h1 className='text-lg font-medium text-brown py-2 px-4'>We Offer a Variety of Room Types for Your Cats</h1>
            <a href="/room-types" className="underline pb-3 block text-center text-brown">{'Explore  Now'}</a>
          </div>
        </SwiperSlide>

        <SwiperSlide className="flex bg-cover bg-center bg-catinnfb1">
        </SwiperSlide>
        
        <SwiperSlide className="flex bg-cover bg-center bg-catinnfb2">
        </SwiperSlide>

        <SwiperSlide className="flex bg-cover bg-center bg-catinnfb3">
        </SwiperSlide>

      </Swiper>

    <h1 className="text-xl font-medium text-orange-500 text-center pt-5 mx-10">{'AVS Licensed Cat Boarding | Exclusive Cat-Only Boarding Services'}</h1>

    <h1 className="text-xl font-medium text-red-500 text-center pt-5 mx-10">
      {'We have relocated to 787 Geylang Rd, #02-01, S(389674)'}
      <img src={newImage} alt='Cat Boarding from www.catinn.sg' className="lg:w-1/2 m-auto" />
    </h1>

    <div className="w-5/6 m-auto mb-20">
      <div className="text-center text-brown mt-4 mb-10 space-y-2 md:w-1/3 mx-auto">
      <a href="/book-viewing" className="flex items-center justify-between w-full px-5 py-2 text-xl font-medium text-center text-brown border-2 border-orange-400 border-solid rounded-lg ease">{'Book Private Viewing'}</a>
      <a href="/cat-boarding" className="flex items-center justify-between w-full px-5 py-2 text-xl font-medium text-center text-brown border-2 border-orange-400 border-solid rounded-lg ease">{'Boarding Registration'}</a>
      </div>   

      <div className="text-medium py-10 text-brown">
        {'T&J Cat Inn is run by passionate cat lovers. We believe that our feline friends deserve the better. All our staff are huge cat lovers and we spend quality time with your kitty throughout the day to give them individual attention! '}
        <br/>
        <br/>
        {'Our brand-new facility has everything your fur baby could ever want and more!'}
      </div>

      {/* moments images list */}
      <div className="text-center text-brown mt-4 mb-10 space-y-2 mx-auto flex justify-center">
        <StandardImageList />   
      </div>
      
      {/* Google Reviews Section */}
      <section className="google-reviews mt-10 px-4">
        <h1 className="text-xl font-medium text-orange-500 text-center mb-6">{'What Our Customers Say'}</h1>

        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {reviews.map((review, index) => (
            <div
              key={index}
              className="p-6 border rounded-lg shadow-lg bg-white hover:shadow-xl transition-shadow"
            >
              <div className="flex items-center mb-4">
                <div className="w-10 h-10 rounded-full bg-orange-400 flex items-center justify-center text-lg font-bold text-white">
                  {review.name.charAt(0)}
                </div>
                <h3 className="ml-4 font-semibold text-lg">{review.name}</h3>
              </div>
              <p className="text-gray-600 mb-4">{review.review}</p>
              <div className="flex">
                {Array.from({ length: review.rating }).map((_, i) => (
                  <StarIcon key={i} className="text-yellow-500" />
                ))}
              </div>
            </div>
          ))}
        </div>

        {/* See More Link */}
        <div className="text-center mt-6">
        <a target='_blank'
          href="https://www.google.com/maps/place/T%26J+Cat+Inn/@1.3147765,103.8916757,17z/data=!4m6!3m5!1s0x31da19aa9ca533ef:0xa0a3e64d28d39bc7!8m2!3d1.3147765!4d103.8916757!16s%2Fg%2F11t0mcc6l_?entry=ttu&g_ep=EgoyMDI1MDMyNS4xIKXMDSoJLDEwMjExNjM5SAFQAw%3D%3D"
          className="text-orange-500 underline hover:text-orange-700 transition-colors" rel="noreferrer"
        >
          See More Reviews
        </a>
      </div>

      </section>

      
    </div>
    </>
  )
}
