import { MenuAlt2Icon, XIcon, ChevronDownIcon, PhoneIcon } from '@heroicons/react/outline'
import { ChatIcon } from '@heroicons/react/outline'
import { Fragment, useState } from 'react'
import { Routes, Route, Link } from "react-router-dom";
import Drawer from '@mui/material/Drawer';
import './NavMenu.css';

const navigation = [
  { name: 'Home', href: '/' },
  { name: 'Cat Boarding', href: '/room-types', submenu: [
    { name: 'Book Private Viewing', href: '/book-viewing' },
    { name: 'Room Types', href: '/room-types' },
    { name: 'Boarding Guide', href: '/boarding-guide' },
    { name: 'Boarding Registration', href: '/cat-boarding' }
  ] },
  // { name: 'Grooming Service', href: '/cat-grooming', submenu: [
  //   { name: 'Service Catalog', href: '/cat-grooming' },
  //   { name: 'Grooming Registration', href: '/grooming-registration' }
  // ] },
  { name: 'Contact Us', href: '/locate-us', submenu: [
    { name: 'Locate Us', href: '/locate-us' },
    { name: 'Payment Mode', href: '/payment' },
  ] },
]

export default function NavMenu() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  return(
    <>
      <div className="top-0">
        <div className="max-w-7xl mx-auto">
          <div className="relative">
              <div className="relative py-6 px-4 sm:px-6 lg:px-8">
                <nav className="relative flex items-center justify-between sm:h-10" aria-label="Global">
                  <div className="flex items-center flex-grow md:flex-grow-0 flex-shrink-0">
                    <div className="flex items-center justify-between w-full md:w-auto">
                      <a href="/" className="w-50 h-50 sm:h-50 catinn-logo">
                      </a>
                      <div className="-mr-2 flex items-center md:hidden">
                        <button onClick={() => setDrawerOpen(true)} className="rounded-md p-2 inline-flex items-center justify-center text-black hover:text-black focus:outline-none">
                          <span className="sr-only">Open main menu</span>
                          <MenuAlt2Icon className="h-9 w-9 text-orange-500" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
                    {navigation.map((menu) => (
                      <div key={menu.name} className="dropdown">
                        <a href={menu.href} className="text-lg font-medium text-gray-500 hover:text-orange-500">{menu.name}</a>{menu.submenu && <ChevronDownIcon className="w-5 h5 inline text-gray-300" />}

                        {menu.submenu &&
                          <div className="dropdown-content">
                          <div className="rounded ring-orange-200 ring-4 md:space-y-3 p-5">
                            {menu.submenu.map((submenu) => (
                              <a key={submenu.name} href={submenu.href} className="whitespace-nowrap text-lg font-medium text-gray-500 hover:text-orange-500">{submenu.name}</a>
                            ))}
                          </div>
                          </div>
                        }
                      </div>

                    ))}
                  </div>
                  <div className="hidden lg:flex justify-center items-center space-x-6">
                      <a href="https://www.facebook.com/TandJ.Cat.Inn/" target="_blank" className="text-gray-400 hover:text-gray-500">
                          <span className="sr-only">Facebook</span>
                          <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                              <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd"></path>
                          </svg>
                      </a>
                      <a href="https://www.instagram.com/tandjcatinn2022/" target="_blank" className="text-gray-400 hover:text-gray-500">
                          <span className="sr-only">Instagram</span>
                          <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                              <path fillRule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clipRule="evenodd"></path>
                          </svg>
                      </a>
                      <a href="https://wa.me/+6587217587" target="_blank" className="text-gray-400"><ChatIcon className="w-6 h-6 inline"/> {'WhatsApp'}</a>
                      <a href="tel:84275351" target="_blank" className="text-gray-400"><PhoneIcon className="w-6 h-6 inline"/> {'Call'}</a>
                  </div>
                </nav>
              </div>
          </div>
        </div>
      </div>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <div className="main-bgcolor h-full overflow-y-auto">
          <div className="px-3 py-6 flex flex-col items-end justify-between">
            <button onClick={() => setDrawerOpen(false)} className="rounded-md p-2 inline-flex items-center justify-center text-black hover:text-black focus:outline-none">
              <span className="sr-only">Close main menu</span>
              <XIcon className="h-9 w-9 text-orange-500" aria-hidden="true" />
            </button>
          </div>
          <div className="px-2 pt-2 pb-3 space-y-1">
            <div className="flex justify-center items-center space-x-6">
                <a href="https://www.facebook.com/TandJ.Cat.Inn/" target="_blank" className="text-gray-400 hover:text-gray-500">
                    <span className="sr-only">Facebook</span>
                    <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                        <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd"></path>
                    </svg>
                </a>
                <a href="https://www.instagram.com/tandjcatinn2022/" target="_blank" className="text-gray-400 hover:text-gray-500">
                    <span className="sr-only">Instagram</span>
                    <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                        <path fillRule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clipRule="evenodd"></path>
                    </svg>
                </a>
                <a href="https://wa.me/+6587217587" target="_blank" className="text-gray-400"><ChatIcon className="w-6 h-6 inline"/></a>
                <a href="tel:84275351" target="_blank" className="text-gray-400"><PhoneIcon className="w-6 h-6 inline"/></a>
            </div>

            {navigation.map((menu) => (
              <div key={menu.name}>
              <a
                href={menu.href}
                className="inline-block font-medium text-xl text-orange-500 hover:text-orange-500 px-3 py-2 rounded-md text-base"
              >
                {menu.name}
              </a>
              {menu.submenu && <ChevronDownIcon className="w-5 h5 inline text-gray-300" />}
              {menu.submenu &&
                <div>
                  {menu.submenu.map((submenu) => (
                    <a key={submenu.name} href={submenu.href} className="block font-medium text-lg text-orange-500 hover:text-orange-500 px-6 py-2 rounded-md text-base">{submenu.name}</a>
                  ))}
                </div>
              }
              </div>
            ))}
          </div>
        </div>
      </Drawer>
    </>
  )
}
