import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Pagination, Autoplay } from "swiper";

export default function CarouselImage({ images }) {
  return (
    <Swiper
      autoplay={{
        delay: 4000
      }}
      pagination={{
        clickable: true,
      }}
      modules={[Autoplay, Pagination]}
      className="w-full max-w-md md:max-w-none"
    >
      {images.map((image, index) => (
        <SwiperSlide key={index}>
          <img
            src={image}
            alt={`Slide ${index + 1}`}
            className="w-full h-auto rounded-lg shadow-md"
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}