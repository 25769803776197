import SGQR from '../documents/sgqr.jpg';

export default function ContactUs() {
  return (
    <div className="w-5/6 m-auto mb-20">
      {/* Page Title */}
      <h1 className="text-4xl font-bold text-orange-500 text-center mt-5 mb-10">
        {'Payment Mode'}
      </h1>

      {/* Bank Transfer Section */}
      <div className="text-gray-700 mb-10">
        <h2 className="font-bold text-2xl mb-4">{'1. Bank Transfer'}</h2>
        <div className="grid grid-cols-2 gap-4">
          <div className="text-right font-medium">
            <div>{'Bank Name:'}</div>
            <div>{'Account Holder Name:'}</div>
            <div>{'Account No:'}</div>
          </div>
          <div className="text-left font-bold">
            <div>{'DBS'}</div>
            <div>{'T&J CAT LOVER PTE. LTD.'}</div>
            <div>{'072-653-5380'}</div>
          </div>
        </div>
      </div>

      {/* PayNow Section */}
      <div className="text-gray-700 mb-10">
        <h2 className="font-bold text-2xl mb-4">{'2. PayNow'}</h2>
        <div className="text-center">
          <img
            src={SGQR}
            alt="SGQR Code"
            className="w-48 h-48 mx-auto rounded-lg shadow-md"
          />
          <p className="mt-4 text-sm text-gray-500">
            {'Scan the QR code above to make a payment via PayNow.'}
          </p>
        </div>
      </div>

      {/* Contact Section */}
      <div className="text-gray-700">
        <h2 className="font-bold text-2xl mb-4">{'3. Contact Us'}</h2>
        <p className="text-sm">
          {'If you have any questions or need assistance with payment, feel free to contact us at '}
          <span className="font-bold text-orange-500">{'+65 8427 5351'}</span>
          {' or email us at '}
          <span className="font-bold text-orange-500">{'admin@catinn.sg'}</span>.
        </p>
      </div>
    </div>
  );
}
